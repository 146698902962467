<template>
    <v-dialog persistent :value="value" width="500">
        <v-card class="text-center">
            <v-card-title class="headline white--text justify-center" :class="color"
                          primary-title>{{ title }}
            </v-card-title>
            <v-icon size="128" color="nolo-dark-grey" class="mt-4">{{ icon }}</v-icon>
            <v-card-text class="d-flex justify-center">
                <v-container>
                <v-row>
                    <v-col cols="12">
                        {{message}}
                    </v-col>
                </v-row>
                <slot></slot>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn @click="acceptOkDialog" :color="color">{{ okText }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'OkDialog',
        props: {
            title: {type: String, default: ''},
            message: {type: String, default: ''},
            okText: {
                type: String, default: function () {
                    return this.$t('btn.ok')
                }
            },
            value: {type: [Boolean, String, Number], default: ''},
            icon: {type: String, default: 'mdi-alert-circle-outline'},
            color: {type: String, default: 'primary'}
        },
        methods: {
            acceptOkDialog() {
                this.$emit('input', false);
                this.$emit('ok');
            }
        }
    }
</script>

