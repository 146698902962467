<template>
    <div>
        <v-overlay color="white" :opacity="0.70" :value="contactingStore">
            <v-container class="text-center">
                <lottie-animation path="./_/img/burger-loop.json"
                                  :width="192"
                                  :height="192"/>
                <div class="nolo-h6 mt-8 mx-8 black--text">{{
                        $t('message.checkingStoreCanAcceptOrder', {
                            storeName:
                            sessStoreName
                        })
                    }}
                </div>
                <div v-if="isSlow" class="nolo-p1 mt-8 mx-8 black--text">{{
                        $t('message.checkingStoreCanAcceptOrderSlow', {storeName: sessStoreName})
                    }}
                </div>
            </v-container>
        </v-overlay>
        <ok-dialog v-model="storeContactError" icon="mdi-alert-circle-outline"
                   :title="$t('failedToContactStore')" @ok="gotoMenu" :message="$t('error.failedToContactStore')">
            <v-row>
                <v-col cols="12">
                    {{ contactError }}
                </v-col>
            </v-row>
        </ok-dialog>

    </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import APIOrder from "../../api/APIOrder";
import OkDialog from "../UI/OkDialog";

export default {
    name: "ContactStore",
    data() {
        return {
            contactingStore: true,
            storeContactError: false,
            isSlow: false,
            slowTimer: null,
            contactError: '',
            recaptchaToken: '',
        }
    },
    components: {
        OkDialog,
        LottieAnimation
    },
    beforeDestroy() {
        this.$recaptchaInstance.hideBadge()
        if (this.slowTimer !== null) {
            window.clearTimeout(this.slowTimer);
            this.slowTimer = null;
        }
    },
    mounted() {
        this.$recaptchaInstance.showBadge()
        this.recaptcha();
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.recaptchaToken = await this.$recaptcha('establishSession')
            this.doContactStore();
        },
        doContactStore() {
            this.slowTimer = window.setTimeout(this.slowToRespond, 5000);
            const me = this;
            APIOrder.establishSession(this, this.recaptchaToken)
                .then((response) => {
                    me.$store.commit('session/isChecked', true);
                    me.appHandoffSuccessful(response.data.data);
                    APIOrder.handoffToPayment(this);
                })
                .catch((error) => {
                    me.$store.commit('session/isChecked', false);
                    me.contactingStore = false;
                    me.storeContactError = true;
                    if (typeof error.response.data.data !== 'undefined') {
                        me.contactError = ''; // removed at William Day's request // error.response.data.data.message;
                        if (typeof error.response.data.data.oos !== 'undefined' && error.response.data.data.oos !== null) {
                            me.contactError = this.$i18n.t('error.outOfStock', {sku: error.response.data.data.oos});
                        }
                    } else {
                        me.contactError = '';
                    }
                })
        },
        gotoMenu() {
            this.storeContactError = false;
            this.$router.push({name: 'order'});
        },
        slowToRespond() {
            this.slowTimer = null;
            this.isSlow = true;
        }
    }
    ,
    computed: {
        sessStoreName() {
            let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
            if (typeof store === 'undefined') return '##STORENAME##';
            return store.name;
        }
        ,
    }
}
</script>
